var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('sb-loading'):_vm._e(),(!_vm.loading && !_vm.schooldistrict)?_c('div',[_vm._v("Geen Schooldistrict gevonden")]):_vm._e(),(_vm.schooldistrict)?[_c('sb-page-header',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('h1',[_vm._v(" "+_vm._s(_vm.schooldistrict.name)+" "),_c('small',[_vm._v("Schooldistrict")]),(!_vm.schooldistrict.active)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("(Gedeactiveerd)")]):_vm._e()])]},proxy:true},{key:"right",fn:function(){return [_c('dropdown',{attrs:{"placement":"bottom-end"}},[_c('i-button',{attrs:{"type":"primary","ghost":""}},[_vm._v(" Acties "),_c('icon',{attrs:{"type":"md-more"}})],1),_c('dropdown-menu',{staticStyle:{"width":"220px"},attrs:{"slot":"list"},slot:"list"},[(_vm.$user.context.isAdmin)?_c('router-link',{attrs:{"to":{
                name: 'ResourceManagementSchooldistrictsSchooldistrictEdit',
                params: {
                  resourceType: 'admin',
                  resourceId: 'default',
                  organisationType: 'schoolDistrict',
                  manageMethod: 'edit',
                },
              }}},[_c('sb-dropdown-button',[_vm._v(" Gegevens bewerken ")])],1):_vm._e(),(['ADMIN', 'PARTNER_MANAGER'].includes(_vm.$user.role))?_c('sb-dropdown-button',{staticStyle:{"color":"red"},on:{"click":function($event){return _vm.$refs.SbDeleteResource.confirmRemoveResource()}}},[_vm._v(" Schooldistrict verwijderen ")]):_vm._e()],1)],1)]},proxy:true}],null,false,3726536013)}),_c('sb-delete-resource',{ref:"SbDeleteResource",attrs:{"resource-id":_vm.schooldistrict.id,"entity":"het schooldistrict"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),(_vm.schooldistrict)?_c('sb-tabbed-page',{attrs:{"data":_vm.schooldistrict}}):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }