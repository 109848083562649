var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('sb-spacer',{attrs:{"height":"40"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[(_vm.$user.context.isAdmin)?_c('i-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
          name: 'ResourceManagementSchooldistrictsSchooldistrictEdit',
          params: {
            resourceType: 'admin',
            resourceId: 'default',
            organisationType: 'schoolDistrict',
            manageMethod: 'edit',
          },
        })}}},[_vm._v(" Gegevens bewerken ")]):_vm._e()],1),_c('sb-organisation-data',{attrs:{"organisation-type":"schoolDistrict","data":_vm.data}}),(_vm.$user.role === 'SCHOOL_DISTRICT_MANAGER')?_c('sb-call-out',{staticStyle:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_vm._v(" ℹ️ ")]},proxy:true},{key:"right",fn:function(){return [_vm._v(" Indien er onjuiste gegevens staan, neem dan contact op met "),_c('a',{attrs:{"href":"mailto: support@brightskills.nl"}},[_vm._v("support@brightskills.nl")]),_vm._v(". ")]},proxy:true}],null,false,1473255426)}):_vm._e(),_c('sb-spacer',{attrs:{"height":"100"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }